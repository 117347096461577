<template>
  <div>
    <Table
      label="Service"
      title-key="name"
      :actions="actions"
      :items="$store.getters['service/tableData']"
      :detail="$store.getters['service/detailData']"
      :fields="fields"
      :keyword="$store.state.service.tableKeyword"
      :pagination="$store.state.service.tablePagination"
      :loading="$store.state.service.loadingTable"
      :filter="$store.state.service.tableFilter"
      :sort="$store.state.service.tableSort"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />

    <!-- Delete Service confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Service"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteService"
    >
      Are you sure want to delete this service?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,
      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'catalog_name',
          label: 'Type',
          type: 'badge',
          map: {
            Service: 'primary',
            Merchandise: 'success',
            'Digital goods': 'warning',
          },
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
          filter: {
            startKey: 'startedAt',
            endKey: 'endedAt',
            type: 'datetime',
          },
        },
        {
          key: 'user.nickname',
          label: 'Creator',
          type: 'profile',
          image: 'user.avatar',
          url: {
            callback(data) {
              return data.user ? `/user/${data.user.id}` : null
            },
          },
        },
        {
          key: 'user.profile.countryCode',
          type: 'country',
          label: 'Creator country',
          filter: {
            key: 'creatorCountries',
            type: 'select',
            options: [
              { value: 'PH', text: 'Philippines' },
              { value: 'TH', text: 'Thailand' },
              { value: 'MY', text: 'Malaysia' },
              { value: 'SG', text: 'Singapore' },
              { value: 'ID', text: 'Indonesia' },
              { value: 'TW', text: 'Taiwan' },
            ],
          },
        },
        {
          key: 'user.email',
          type: 'email',
          label: 'Creator email',
        },
        {
          key: 'type',
          label: 'Type',
          type: 'dot',
          map: {
            session: 'success',
            hour: 'warning',
            primary: 'quantity',
          },
        },
        {
          key: 'category.name',
          label: 'Category',
        },
        {
          key: 'description',
          label: 'Description',
          visibility: ['view'],
        },
        {
          key: 'summary.averageStars',
          label: 'Rating',
          type: 'rating',
        },
        {
          key: 'status',
          label: 'Status',
          type: 'dot',
          map: {
            active: 'success',
            inactive: 'secondary',
          },
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
        {
          key: 'instructionsOn',
          label: 'Instructions On',
        },
        {
          key: 'instructions',
          label: 'Instructions',
          visibility: ['view'],
        },
      ],

      actions: ['view', 'update',
        {
          type: 'other',
          children: [
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'Service'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('service/getTableData')
    },
    filterData(val) {
      this.$store.commit('service/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('service/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('service/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('service/SET_TABLE_KEYWORD', val)
    },
    loadDetail(id) {
      return this.$store.dispatch('service/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('service/SET_DETAIL_DATA', undefined)
    },
    deleteService() {
      this.$store.dispatch('service/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The service has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
